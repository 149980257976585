body {
    width: 100vw;
    height: 100vh;
    margin: 0px;
}

.loading-col {
    text-align: center;
    padding: 100px !important;
}

.input-danger {
    border-color: red !important;
}

.floating-alert {
    z-index: 99999;
    position: absolute !important;
    margin: 10px !important;
    top: 0 !important;
    left: 0 !important;
}

.red-btn {
    border-color: red !important;
}

.btn-addon-80 {
    border-color: white !important;
    border-radius: 50% !important;
    height: 80px !important;
    width: 80px !important;
    background-color: white !important;
}


.btn-addon-50 {
    border-color: white !important;
    border-radius: 50% !important;
    height: 50px !important;
    width: 50px !important;
    background-color: white !important;
}

.floating-btn {
    z-index: 9999;
    position: absolute !important;
    margin: 20px !important;
    top: 0 !important;
    right: 0 !important;
    border: 3px solid #FFF;
    border-radius: 50%;
}


.floating-menu-btn {
    z-index: 9999;
    position: absolute !important;
    margin: 20px !important;
    top: 0 !important;
    left: 0 !important;
    border: 3px solid #FFF;
    border-radius: 50%;
}


.floating-public-chat-btn {
    z-index: 9999;
    position: absolute !important;
    margin: 20px !important;
    bottom: 0 !important;
    left: 0 !important;
    border: 3px solid #FFF;
    border-radius: 50%;
}

.floating-shopping-btn {
    z-index: 9999;
    position: absolute !important;
    margin: 20px !important;
    bottom: 0 !important;
    right: 0 !important;
    border: 3px solid #FFF;
    border-radius: 50%;
}

.brand-btn {
    background-color: #FFF !important;
    border: 1px solid rgb(231, 115, 19) !important;
    border-radius: 8px;
    color: rgb(231, 115, 19) !important;
    padding: 10px !important;
}

.text-danger {
    color: red;
}

.small-text {
    font-size: 0.9rem !important;
}

.light-text {
    color: rgb(138, 136, 136) !important;
}

.rounded-input {
    border-radius: 5px !important;
}

.map {
    height: 100%;
}

.map-card {
    z-index: 999;
    margin: 0px !important;
    top: 0 !important;
    right: 0 !important;
    position: absolute !important;
    background-color: rgb(66, 47, 85) !important;
    color: #FFF !important;
    border-bottom-left-radius: 16px;
    padding: 20px;
}

.chat-card {
    z-index: 999;
    height: 100% !important;
    margin: 0px !important;
    top: 0 !important;
    left: 0 !important;
    position: absolute !important;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(2, 2, 2) !important;
   /*  border-top-left-radius: 16px;
    border-bottom-left-radius: 16px; */
    padding: 20px;
}

.close-chat {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px !important;
    cursor: pointer;
}

.close-icon {
    height: 24px;
}

.chat-icon {
    height: 24px;
    margin-top: -5px !important;
}

.ruby-icon {
    height: 14px;
    margin-top: -5px !important;
}

.crown-icon {
    height: 22px;
    margin-right: 3px !important;
    margin-top: -5px !important;
}

.map-card-container {
    height: 100%;
}

.pop-card {
    padding: 10px;
}

#root {
    height: 100%;
}


.small-text {
    font-size: 0.8rem !important;
}

.my-lb-bubble {
    border-radius: 8px !important;
    background: rgb(221, 221, 221);
    padding: 6px !important;
    margin-bottom: 6px !important;
    color: rgb(56, 55, 55) !important;
}

.my-bubble {
    border-radius: 8px !important;
    background: rgb(221, 221, 221);
    padding: 6px !important;
    margin-bottom: 6px !important;
    color: rgb(141, 141, 141) !important;
}

.other-bubble {
    border-radius: 8px !important;
    background: rgb(220, 228, 252);
    padding: 6px !important;
    margin-bottom: 6px !important;
    color: black !important;
}

.chat-input {
    border-radius: 8px !important;
    width: 100%;
}

.chat-margin {
    margin: 0 !important;
}

.button-send {
    background-color: rgb(10, 10, 10) !important;
    color: white !important;
    min-width: 80px !important;
    padding: 6px !important;
    border-radius: 6px;
}

.mt-1 {
    padding-top: 1px !important;
}

.mt-2 {
    padding-top: 2px !important;
}

.mt-3 {
    padding-top: 3px !important;
}

.mt-4 {
    padding-top: 4px !important;
}

.mt-5 {
    padding-top: 5px !important;
}

.b-b-1 {
    padding-bottom: 4px !important;
    border-bottom: 1px solid rgb(219, 218, 218);
}

.avatar {
    max-height: 80px !important;
}